import React from 'react'
import styles from './explication-application.module.css'
import { Card, Row, Col } from 'react-bootstrap';
import SousTitrePage from '../commun/titre-page';

export default function ExplicationApplication() {
    return (
        <>
            <Row className={styles.conteneur_presentation_app}>
                <Col lg="2" md="1" xs="1"></Col>
                <Col lg="4" md="4" className="text-center">
                    <img 
                        className={'img-fluid ' + styles.image_application} 
                        src="/images/capture-ecran-application/page_accueil.jpg"
                        alt="Capture d'écran de la page d'accueil de l'application Emotions."
                    />
                </Col>
                <Col className={styles.conteneur_bouton_app + " text-center"} lg="4" md="6" xs="12">
                    <p>Emotions, un projet d'application mobile sur Android et sur iOS, permet à nos utilisateurs de tous âges de découvrir les événements les plus populaires du Québec.</p>
                    <Row>
                        <Col lg="2" xs="0"></Col>
                        <Col lg="8" xs="12">
                            <a 
                                target='_blank'
                                rel="noreferrer"
                                href='https://play.google.com/store/apps/details?id=com.greenwoodmultimedia.emotionsapp'
                            >
                                <img 
                                    className={'img-fluid'}
                                    src="/images/google-play/google-play-badge.png"
                                    alt="Bouton permettant de télécharger l'application Emotions sur Google Play."
                                />
                            </a>
                        </Col>
                        <Col lg="2" xs="0"></Col>
                    </Row>
                    <Row>
                        <Col lg="2" xs="0"></Col>
                        <Col lg="8" xs="12">
                            <a 
                                target='_blank'
                                rel="noreferrer"
                                href='https://apps.apple.com/ca/app/emotions/id6478182542'
                            >
                                <img 
                                    className={'img-fluid'}
                                    width={"100%"}
                                    src="/images/ios-store/ios-store-badge.svg"
                                    alt="Bouton permettant de télécharger l'application Emotions sur Google Play."
                                />
                            </a>
                        </Col>
                        <Col lg="2" xs="0"></Col>
                    </Row>
                </Col>
                <Col lg="2" md="0" xs="1"></Col>
            </Row>
            <SousTitrePage titre_page="Fonctionnalités"></SousTitrePage>
            <Row>
                <Col xs="3"></Col>
                <Col xs="6" className="text-center">
                    <Card className={styles.carte}>
                        <Card.Body>
                            <Card.Title className={styles.titre_fonctionnalite}>Événements en continus</Card.Title>
                            <Card.Text className={styles.texte_fonctionnalite}>
                            Restez informé des évènements dans les alentours.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="3"></Col>
            </Row>
            <Row>
                <Col xs="3"></Col>
                <Col xs="6" className="text-center">
                    <Card className={styles.carte}>
                        <Card.Body>
                            <Card.Title className={styles.titre_fonctionnalite}>Suggestions personnalisées</Card.Title>
                            <Card.Text className={styles.texte_fonctionnalite}>
                            Oui ! Notre projet d'application mobile est de bien vous servir selon vos goûts de sorties.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="3"></Col>
            </Row>
            <Row>
                <Col xs="3"></Col>
                <Col xs="6" className="text-center">
                    <Card className={styles.carte}>
                        <Card.Body>
                            <Card.Title className={styles.titre_fonctionnalite}>Agenda personnalisé</Card.Title>
                            <Card.Text className={styles.texte_fonctionnalite}>
                            Simple et efficace. Votre calendrier d'événements en poche !
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="3"></Col>
            </Row>
            <Row>
                <Col xs="3"></Col>
                <Col xs="6" className="text-center">
                    <Card className={styles.carte}>
                        <Card.Body>
                            <Card.Title className={styles.titre_fonctionnalite}>Régions desservies</Card.Title>
                            <Card.Text className={styles.texte_fonctionnalite}>
                            Centre-du-Québec et plus à venir.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="3"></Col>
            </Row>
        </>
    );
  }