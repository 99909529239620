import React, { useEffect } from 'react'
import DocumentMeta from 'react-document-meta';
import { useState } from 'react';
import Navigation from '../composants/commun/navigation';
import PiedPage from '../composants/commun/pied-page';
import ReactGA from "react-ga4";
import TitrePage from '../composants/commun/titre-page';
import TexteTermeEtCondition from '../composants/terme-et-condition/texte-terme-et-condition';

export default function PageTermeEtCondition() {
  const [state] = useState({
    meta: {
      title: 'Application Emotions | Termes et conditions',
      description: 'Termes et conditions acceptés par les utilisateurs de l\'application Emotions.',
      canonical: 'https://appliemotions.com/termes-et-conditions'
    } 
  });

  useEffect(() => {
    ReactGA.initialize("G-18846NY26L");
    ReactGA.send({ hitType: "pageview", page: "/termes-et-conditions", title: "Appli Emotions | Termes et conditions" });
  }, [])

  return (
    <DocumentMeta {...state.meta}>
      <Navigation></Navigation>
      <TitrePage titre_page="Termes et conditions"></TitrePage>
      <TexteTermeEtCondition></TexteTermeEtCondition>
      <PiedPage></PiedPage>
    </DocumentMeta>
  );
}