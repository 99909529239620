import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert, Button, Form, FormGroup, FormLabel } from 'react-bootstrap';
import styles from "./form-demande-suppression-compte.module.css";
import { envoyer_courriel_reinitialisation } from '../../services/courrielService';
import { supprimer_compte } from '../../services/utilisateurService';

export default function FormDemandeSuppressionCompte() {

    const [state, setState] = useState({
        infosSuppression: {
            courriel: "",
            code_reinitialisation: ""
        },
        etapeSuppression: 1,
        alerteSuppression: {
            type: "",
            texte: ""
        }
    })

    return (
        <Row className='text-center'>
            <Col lg="4" xs="2"></Col>
            <Col lg="4" xs="8">
                <p>Entrer le courriel associé à votre compte afin d'amorcer le processus de suppression.</p>
                {
                    state.alerteSuppression.type &&
                    <Alert variant={state.alerteSuppression.type}>{state.alerteSuppression.texte}</Alert>
                }
                {
                    state.etapeSuppression === 1 &&
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault();
                            envoyer_courriel_reinitialisation(state, setState)
                        }}
                    >
                        <FormGroup>
                            <FormLabel className={styles.form_support_label}>Votre courriel :</FormLabel>
                            <input 
                                type='email'
                                className='form-control'
                                placeholder='johndoe@domaine.com'
                                value={state.infosSuppression.courriel}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosSuppression: {
                                            ...state.infosSuppression,
                                            courriel: event.target.value
                                        },
                                        alerteDemande: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                            />
                        </FormGroup>
                        <Button className={styles.bouton_envoi_demande} variant='primary' type='submit'>Commencer la demande</Button>
                    </Form>
                }
                {
                    state.etapeSuppression === 2 &&
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault();
                            supprimer_compte(state, setState)
                        }}
                    >
                        <FormGroup>
                            <FormLabel className={styles.form_support_label}>Votre code de réinitialisation :</FormLabel>
                            <input 
                                type='text'
                                className='form-control'
                                placeholder='123456'
                                value={state.infosSuppression.code_reinitialisation}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosSuppression: {
                                            ...state.infosSuppression,
                                            code_reinitialisation: event.target.value
                                        },
                                        alerteDemande: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                            />
                        </FormGroup>
                        <Button className={styles.bouton_envoi_demande} variant='primary' type='submit'>Confirmer la demande de suppression</Button>
                    </Form>
                }
            </Col>
            <Col lg="4" xs="2"></Col>
        </Row>
    );
  }