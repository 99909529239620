import React from 'react';
import Navigation from '../composants/commun/navigation';
import PiedPage from '../composants/commun/pied-page';
import ContenuPageNonTrouve from '../composants/page-non-trouve/contenu-page-non-trouve';

export default function PageNonTrouve() {
  return (
    <>
      <Navigation></Navigation>
      <ContenuPageNonTrouve></ContenuPageNonTrouve>
      <PiedPage></PiedPage>
    </>
  );
}