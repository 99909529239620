import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TitrePage from '../commun/titre-page';
import styles from "./contenu-page-non-trouve.module.css";
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function ContenuPageNonTrouve() {
    const navigate = useNavigate();

    return (
        <Row className='text-center'>
            <Col xs="4"></Col>
            <Col xs="4">
                <TitrePage titre_page="Page non trouvé"></TitrePage>
                <p>La page que vous avez tenté d'accéder n'existe pas.</p>
                <Button
                    onClick={() => {
                        navigate("/")
                    }}
                    className={styles.bouton_retour_page_accueil}
                >Retourner à la page d'accueil</Button>
            </Col>
            <Col xs="4"></Col>
        </Row>
    );
  }