import React, { useEffect } from 'react'
import DocumentMeta from 'react-document-meta';
import { useState } from 'react';
import Navigation from '../composants/commun/navigation';
import PiedPage from '../composants/commun/pied-page';
import ReactGA from "react-ga4";
import TitrePage from '../composants/commun/titre-page';
import FormSupportTechnique from '../composants/support-technique/form-support-technique';

export default function PageSupportTechnique() {
  const [state] = useState({
    meta: {
      title: 'Application Emotions | Support technique',
      description: 'Obtenir du support technique concernant l\'application mobile Emotions.',
      canonical: 'https://appliemotions.com/support-technique'
    } 
  });

  useEffect(() => {
    ReactGA.initialize("G-18846NY26L");
    ReactGA.send({ hitType: "pageview", page: "/support-technique", title: "Application Emotions | Support technique" });
  }, [])

  return (
    <DocumentMeta {...state.meta}>
      <Navigation></Navigation>
      <TitrePage titre_page="Support technique"></TitrePage>
      <FormSupportTechnique></FormSupportTechnique>
      <PiedPage></PiedPage>
    </DocumentMeta>
  );
}