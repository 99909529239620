import validator from 'validator';
import { CONSTANTS } from "../config/constants";
import axios from 'axios';

export function envoyer_courriel_reinitialisation(state, setState)
{
    if(validator.isEmpty(state.infosSuppression.courriel))
    {
        setState({
            ...state,
            alerteDemande:{
                type:"warning",
                texte:"Veuillez remplir votre courriel !"
            }
        })
        return;
    }
    if(!validator.isEmpty(state.infosSuppression.courriel) && !validator.isEmail(state.infosSuppression.courriel))
    {
        setState({
            ...state,
            alerteDemande:{
                type:"warning",
                texte:"Veuillez remplir votre courriel selon le format 'johndoe@mondomaine.com' !"
            }
        })
        return;
    }

    axios.post(
        CONSTANTS.nomDomaineApi + "/courriel/reinitalisation",
        {
            "courriel": state.infosSuppression.courriel
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    etapeSuppression: 2,
                    alerteSuppression:{
                        type:"success",
                        texte:"Un code de réinitialisation vous a été envoyé, veuillez l'entrer afin de confirmer votre demande de suppression de compte !"
                    }
                })
                return;
            }
            setState({
                ...state,
                alerteSuppression:{
                    type:"success",
                    texte:"Une erreur inattendue est survenue !"
                }
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                alerteSuppression:{
                    type:"success",
                    texte:"Une erreur inattendue est survenue !"
                }
            })
        })
}

export function envoyer_demande_support(state, setState)
{
    if(validator.isEmpty(state.infosClient.prenom))
    {
        setState({
            ...state,
            alerteDemande:{
                type:"warning",
                texte:"Veuillez remplir votre prénom !"
            }
        })
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
        return;
    }
    if(validator.isEmpty(state.infosClient.nom))
    {
        setState({
            ...state,
            alerteDemande:{
                type:"warning",
                texte:"Veuillez remplir votre nom !"
            }
        })
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
        return;
    }
    if(validator.isEmpty(state.infosClient.courriel))
    {
        setState({
            ...state,
            alerteDemande:{
                type:"warning",
                texte:"Veuillez remplir votre courriel !"
            }
        })
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
        return;
    }
    if(!validator.isEmpty(state.infosClient.courriel) && !validator.isEmail(state.infosClient.courriel))
    {
        setState({
            ...state,
            alerteDemande:{
                type:"warning",
                texte:"Veuillez remplir votre courriel selon le format 'johndoe@mondomaine.com' !"
            }
        })
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
        return;
    }
    if(validator.isEmpty(state.infosClient.sujet))
    {
        setState({
            ...state,
            alerteDemande:{
                type:"warning",
                texte:"Veuillez remplir le sujet de votre demande !"
            }
        })
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
        return;
    }
    if(validator.isEmpty(state.infosClient.message))
    {
        setState({
            ...state,
            alerteDemande:{
                type:"warning",
                texte:"Veuillez remplir le message de votre demande !"
            }
        })
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
        return;
    }

    axios.post(
        CONSTANTS.nomDomaineApi + "/courriel/support-technique",
        state.infosClient
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    alerteDemande:{
                        type:"success",
                        texte:"Votre demande de support a bel et bien été envoyé !"
                    }
                })
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                })
                return;
            }
            setState({
                ...state,
                alerteDemande:{
                    type:"success",
                    texte:"Une erreur inattendue est survenue !"
                }
            })
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                alerteDemande:{
                    type:"success",
                    texte:"Une erreur inattendue est survenue !"
                }
            })
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            })
        })
}