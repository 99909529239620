import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import PageAccueil from './pages/page-accueil';
import PagePolitiqueDeConfidentalite from './pages/page-politique-confidentialite';
import PageTermeEtCondition from './pages/page-terme-et-condition';
import PageSupportTechnique from './pages/page-support-technique';
import PageNonTrouve from './pages/page-non-trouve';
import PageDemandeSuppressionCompte from './pages/page-demande-suppression-compte';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<PageAccueil />} />
      <Route path="/politique-de-confidentialite" element={<PagePolitiqueDeConfidentalite />} />
      <Route path="/termes-et-conditions" element={<PageTermeEtCondition />} />
      <Route path="/support-technique" element={<PageSupportTechnique />} />
      <Route path="/supprimer-compte" element={<PageDemandeSuppressionCompte />} />
      <Route path="*" element={<PageNonTrouve />} />
    </Routes>
  </BrowserRouter>
);