import React, { useEffect } from 'react'
import DocumentMeta from 'react-document-meta';
import { useState } from 'react';
import Navigation from '../composants/commun/navigation';
import PiedPage from '../composants/commun/pied-page';
import ReactGA from "react-ga4";
import TitrePage from '../composants/commun/titre-page';
import TextePolitiqueConfidentialite from '../composants/politique-de-confidentialite/texte-politique-confidentialite';

export default function PagePolitiqueDeConfidentalite() {
  const [state] = useState({
    meta: {
      title: 'Application Emotions | Politique de confidentialité',
      description: 'Politique de confidentialité informant comment les données des utilisateurs sont utilisés dans l\'application Emotions.',
      canonical: 'https://appliemotions.com/politique-de-confidentialite'
    } 
  });

  useEffect(() => {
    ReactGA.initialize("G-18846NY26L");
    ReactGA.send({ hitType: "pageview", page: "/politique-de-confidentialite", title: "Application Emotions | Politique de confidentialité" });
  }, [])

  return (
    <DocumentMeta {...state.meta}>
      <Navigation></Navigation>
      <TitrePage titre_page="Politique de confidentialité"></TitrePage>
      <TextePolitiqueConfidentialite></TextePolitiqueConfidentialite>
      <PiedPage></PiedPage>
    </DocumentMeta>
  );
}