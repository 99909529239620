import React, { useEffect } from 'react'
import DocumentMeta from 'react-document-meta';
import { useState } from 'react';
import Navigation from '../composants/commun/navigation';
import PiedPage from '../composants/commun/pied-page';
import ReactGA from "react-ga4";
import TitrePage from '../composants/commun/titre-page';
import FormDemandeSuppressionCompte from '../composants/supprimer-compte/form-demande-suppression-compte';

export default function PageDemandeSuppressionCompte() {
  const [state] = useState({
    meta: {
      title: 'Application Emotions | Demande de suppression de compte',
      description: 'Cette page vous permet de demander la suppression de votre compte Emotions.',
      canonical: 'https://appliemotions.com/supprimer-compte'
    } 
  });

  useEffect(() => {
    ReactGA.initialize("G-18846NY26L");
    ReactGA.send({ hitType: "pageview", page: "/supprimer-compte", title: "Application Emotions | Demande de suppression de compte" });
  }, [])

  return (
    <DocumentMeta {...state.meta}>
      <Navigation></Navigation>
      <TitrePage titre_page="Demande de suppression de compte"></TitrePage>
      <FormDemandeSuppressionCompte></FormDemandeSuppressionCompte>
      <PiedPage></PiedPage>
    </DocumentMeta>
  );
}