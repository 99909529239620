import React from 'react'
import { Row, Col } from 'react-bootstrap';
import SousTitrePage from '../commun/titre-page';
import styles from './conclusion-application.module.css';

export default function ConclusionApplication() {
    return (
        <>
            <SousTitrePage className={styles.titre_message_prioprio} titre_page="Merci à vous !"></SousTitrePage>
            <Row>
                <Col lg="3" xs="1"></Col>
                <Col lg="6" xs="10" className="text-center">
                    <p className={styles.signature_proprio}>Un concept créé et financé par Claude-Olivier Pepin, Simon-Pierre Therrien et Christopher Boisvert pour votre divertissement, car notre plaisir est le vôtre !</p>
                </Col>
                <Col lg="3" xs="1"></Col>
            </Row>
        </>
    );
  }