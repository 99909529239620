import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert, Button, Form, FormGroup, FormLabel } from 'react-bootstrap';
import styles from "./form-support-technique.module.css";
import { envoyer_demande_support } from '../../services/courrielService';

export default function FormSupportTechnique() {

    const [state, setState] = useState({
        infosClient: {
            prenom: "",
            nom: "",
            courriel: "",
            sujet: "",
            message: ""
        },
        alerteDemande: {
            type: "",
            texte: ""
        }
    })

    return (
        <Row className='text-center'>
            <Col lg="4" xs="1"></Col>
            <Col lg="4" xs="10">
                <p>Écrivez-nous un court message afin d'obtenir de l'aide. Nous vous répondrons dans les plus brefs délais possible.</p>
                {
                    state.alerteDemande.type &&
                    <Alert variant={state.alerteDemande.type}>{state.alerteDemande.texte}</Alert>
                }
                <Form
                    onSubmit={(event) => {
                        event.preventDefault();
                        envoyer_demande_support(state, setState)
                    }}
                >
                    <FormGroup>
                        <FormLabel className={styles.form_support_label}>Votre prénom :</FormLabel>
                        <input 
                            type='text'
                            className='form-control'
                            placeholder='John'
                            value={state.infosClient.prenom}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    infosClient: {
                                        ...state.infosClient,
                                        prenom: event.target.value
                                    },
                                    alerteDemande: {
                                        type: "",
                                        texte: ""
                                    }
                                })
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel className={styles.form_support_label}>Votre nom :</FormLabel>
                        <input 
                            type='text'
                            className='form-control'
                            placeholder='Doe'
                            value={state.infosClient.nom}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    infosClient: {
                                        ...state.infosClient,
                                        nom: event.target.value
                                    },
                                    alerteDemande: {
                                        type: "",
                                        texte: ""
                                    }
                                })
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel className={styles.form_support_label}>Votre courriel :</FormLabel>
                        <input 
                            type='email'
                            className='form-control'
                            placeholder='johndoe@domaine.com'
                            value={state.infosClient.courriel}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    infosClient: {
                                        ...state.infosClient,
                                        courriel: event.target.value
                                    },
                                    alerteDemande: {
                                        type: "",
                                        texte: ""
                                    }
                                })
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel className={styles.form_support_label}>Votre sujet :</FormLabel>
                        <input 
                            type='text'
                            className='form-control'
                            placeholder='Problème XYZ'
                            value={state.infosClient.sujet}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    infosClient: {
                                        ...state.infosClient,
                                        sujet: event.target.value
                                    },
                                    alerteDemande: {
                                        type: "",
                                        texte: ""
                                    }
                                })
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel className={styles.form_support_label}>Votre message :</FormLabel>
                        <textarea
                            className='form-control'
                            placeholder='Explication du problème encourue...'
                            value={state.infosClient.message}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    infosClient: {
                                        ...state.infosClient,
                                        message: event.target.value
                                    },
                                    alerteDemande: {
                                        type: "",
                                        texte: ""
                                    }
                                })
                            }}
                        ></textarea>
                    </FormGroup>
                    <Button className={styles.bouton_envoi_demande} variant='primary' type='submit'>Envoyer ma demande</Button>
                </Form>
            </Col>
            <Col lg="4" xs="1"></Col>
        </Row>
    );
  }